module.exports = {
  siteUrl: 'https://fidelius.llc/',
  siteAuthor: 'Fidelius Consulting, LLC',
  coprYears: '2020',
  siteTitle: 'Fidelius Consulting', // <title>
  contactEmail: '%22Fidelius%20Consulting%22%20%3cinfo@fidelius.llc%3e', 
  manifestName: 'FideliusConsultingLLC',
  manifestShortName: 'FideliusLLC', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
//  pathPrefix: `/gatsby-starter-spectral/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Fidelius Consulting',
  subHeading: 'Providing trusted solution design and programming services',
  // social
  socialLinks: [
  /*
    {
      style: 'brands',
      icon: 'fa-github',
      name: 'Github',
      url: 'https://github.com/',
    },
    {
      style: 'brands',
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/',
    },
    */
    {
      style: 'solid',
      icon: 'fa-envelope',
      name: 'info@fidelius.llc',
      url: 'mailto:%22Fidelius%20Consulting%22%20%3cinfo@fidelius.llc%3e',
    },
    {
      style: 'solid',
      icon: 'fa-phone-volume',
      name: '+1 (205) 610-9119',
      url: 'tel:+1-205-610-9119',
    },
  ],
};
