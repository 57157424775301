import React from 'react';
import { StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import config from '../../config';

export function Footer( {data} ) {
  return (
    <footer id="footer">
      <ul className="icons">
        {config.socialLinks.map(social => {
          const { style, icon, name, url } = social;
          return (
            <li key={url}>
              <a href={url} className={`icon ${style} ${icon}`}>
                <span className="label">{name}</span>
              </a>
            </li>
          );
        })}
      </ul>
      <ul className="copyright">
        <li>&copy; {data.site.siteMetadata.coprYears} {data.site.siteMetadata.author}</li>
        <li>
          Design: <a href="http://html5up.net">HTML5 UP</a>
        </li>
      </ul>
    </footer>
  );
}

export default props => (
  <StaticQuery query={graphql`
      query {
        site {
          siteMetadata {
            author,
            coprYears
          }
        }
      }
    `} render={data => <Footer data={data} {...props} />} />
)

Footer.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        author: PropTypes.string.isRequired,
        coprYears: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired
}